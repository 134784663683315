.button-popup {
    position: absolute;
    z-index: 99999999;
    transform: translateX(-50%);

    padding: .13rem .25rem;
    margin-top: .25rem;
    background-color: var(--secondary-color);
    border-radius: .25rem;
    border: .13rem solid var(--primary-border-color);
}