.game-screen .content > .player-list-menu {
    width: 20%;
}
@media only screen and (max-width: 600px) {
    .game-screen .content > .player-list-menu {
        width: 100%;
    }
}

.player-list-menu > button {
    margin: .13rem;
}
.player-list-menu {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}

.player-list-menu .player-list {
    display: flex;
    flex-direction: column;
    gap: .25rem;
}

.player-list-menu .player {
    display: flex;
    flex-direction: column;
    padding-left: .13rem;
    border-top: .13rem solid var(--primary-border-color);
    border-bottom: .13rem solid var(--primary-border-shadow-color);
    border-left: .13rem solid transparent;
    border-right: .13rem solid transparent;
    background-color: var(--secondary-color);
}

.player-list-menu .dead-players-separator {
    border-top: .13rem solid var(--primary-border-color);
    border-bottom: .13rem solid var(--primary-border-shadow-color);
    border-left: .13rem solid transparent;
    border-right: .13rem solid transparent;
    background-color: var(--secondary-color);
    margin: 1rem 0 0;
}

.player-list-menu .player.highlighted {
    outline: .13rem solid yellow;
}

.player-list-menu .player .top {
    text-align: left;
    text-wrap: wrap;
    padding: 0.25rem 0;
}
.player-list-menu .player .voted-popup {
    position: relative;
    top: 0;

}
.player-list-menu .buttons {
    display: grid;
    grid-template-columns: min-content 1fr 1fr;
}
.player-list-menu .buttons > div {
    width: 1fr;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.player-list-menu .buttons > div > button {
    flex-grow: 1;
}
.player-list-menu div.chat-buttons {
    grid-column: 1;
    width: min-content;
    flex-wrap: nowrap;
}
.player-list-menu .day-target {
    grid-column: 2;
}
.player-list-menu .target-or-vote {
    grid-column: 3;
}
.player-list-menu .playerTags{
    font-size: 1.125em;
}

.player-list-menu .phase-specific{
    background-color: var(--primary-color);
    border-top: .13rem solid var(--primary-border-color);
    padding: .13rem .25rem;
}
