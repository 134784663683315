

.grave {
    white-space: normal;
    width: 100%;
}
.grave .note-area {
    background-color: var(--secondary-color);
    margin-bottom: 0.5rem;
    text-align: left;
}