.large-auditor-menu .grid {
    display: grid;
    padding: .13rem;
    gap: .13rem .25rem;
    margin-bottom: 1rem;
    width: 100%;
    grid-template-columns: [role-list-start] 50% [role-list-end graves-start] 50% [graves-end];
}
.large-auditor-menu > .grid button {
    text-align: left;
    margin: 0;
    text-wrap:pretty;
    overflow-x: auto;
    overflow-y: hidden;
}
.large-auditor-menu > .grid label {
    display: flex;
    align-items: center;
    padding-left: .2rem;
    text-align: left;
    margin: 0;
    text-wrap: pretty;
    overflow-x: auto;
    overflow-y: hidden;
    background-color: var(--primary-color);
    border: .13rem solid var(--primary-border-color);
}
/* .large-auditor-menu > .grid button.role-list-button {
    grid-column: role-list-start / role-list-end;
}
.large-auditor-menu > .grid button.grave-list-button {
    grid-column: graves-start / graves-end;
} */
.large-auditor-menu > .grid button > *{
    width: 100%;
    text-align: left;
    overflow-x: scroll;
}