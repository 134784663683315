.standalone-wiki {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    padding: 1rem;
    background-color: var(--background-color);
}
.standalone-wiki > header > h2 {
    font-size: 300%;
}
.standalone-wiki > div {
    overflow-y: auto;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .standalone-wiki {
        border: none;
        padding: 0;
    }
}