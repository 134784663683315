
@font-face {
    font-family: 'Material Icons Round';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
        local('MaterialIconsRound-Regular'),
        url(../../public/MaterialIconsRound-Regular.ttf) format('truetype');
}

.material-icons-round {
    font-family: 'Material Icons Round';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.icon {
    font-size: 1.2em;
    user-select: none;
}

button .icon {
    vertical-align: text-top;
}

.icon-small {
    font-size: 1em;
    vertical-align:auto;
}

.icon-tiny {
    font-size: 0.75em;
    vertical-align:auto;
}  