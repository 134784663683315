
.chat-message-div-copy-button {
    position: absolute;
    font-size: 1rem;
    right: 0;
    bottom: 0;
    padding: 0.1rem;
    border-radius: 0.2rem;
}
.chat-message-div > details {
    width: 100%;
}
.chat-message-div {
    position: relative;
    max-width: 100%;
    display: flex;
    align-items: center;
}
.chat-message {
    width: 100%;
    text-align: left;
    margin: .1rem;
    margin-left: 0;
    margin-right: 0;
    overflow-wrap: anywhere;
    tab-size: 1rem;
}
.chat-message > p {
    color: inherit;
    text-align: inherit;
    text-decoration: inherit;
    font: inherit;
}
.chat-message * {
    text-align: left;
}
.chat-message ul, .chat-message ol {
    padding-left: 1rem;
    list-style-position: inside;
}
/* Chat message variants */
.chat-message.player {
    text-indent: -1rem;
    padding-left: 1rem;
}
.chat-message.mention {
    /* Since it's so blurred, this shadow acts as a highlight overlay. */
    box-shadow: inset 0 0 0 100vmax #b8860b22;
}
.chat-message.dead {
    background-color: black;
    border-radius: .5rem;
    color: grey;
}
.chat-message.phase-change {
    text-align: center;
    background-color: var(--primary-color);
    color: yellow;
    text-decoration: underline;
}
.chat-message.phase-change .keyword-info{
    color: yellow;
}
.chat-message.center {
    text-align: center;
}
.chat-message.special {
    color: violet;
}
.chat-message.discreet, .discreet {
    color: turquoise;
}
.chat-message.important {
    color: yellow;
}
.chat-message.will {
    border-top: .13rem solid var(--primary-border-color);
    border-bottom: .13rem solid var(--primary-border-color);
}
.chat-message.deathNote {
    border-top: .13rem solid var(--primary-border-color);
    border-bottom: .13rem solid var(--primary-border-color);
    background-color: #ff000022;
}
.chat-message.trial {
    color: orange;
}
.chat-message.target {
    color: orange;
}
.chat-message.result {
    background-color: #003300;
    color: #aaffaa;
}
.chat-message.warning {
    background-color: #660000;
}
.grave-message {
    border: .13rem solid var(--background-border-color);
    border-bottom-color: var(--background-border-shadow-color);
    border-right-color: var(--background-border-shadow-color);
    border-radius: .5rem;
    margin: .13rem;
    padding: .25rem;
}

.kira-guess-results {
    display: flex;
    flex-direction: column;
    align-items: left;
}
.kira-guess-result {
    width: 100%;
    align-items: left;
    flex-direction: row;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.4rem;
    border-width: .07rem;
    border-style: solid;
    border-color: black;
}
.kira-guess-result.correct{
    border-color: limegreen;
}
.kira-guess-result.wrongSpot{
    border-color: yellow;
}
.kira-guess-result.notInGame{
    border-color: red;
}