.large-journalist-menu {
    padding-top: .2rem;
}
.large-journalist-menu textarea {
    text-align: left;
    height: 19em;
    resize: none;
    width: 100%;
    margin: 0;
}
.large-journalist-menu div {
    margin: .2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.large-journalist-menu label {
    display: inline-block;
    height: 100%;
    padding: .2rem;
    border-radius: .2rem;
    background-color: var(--primary-color);
    border: .13rem solid var(--primary-border-color);
    cursor: pointer;
    margin-right: .24rem;
}