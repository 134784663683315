.settings-menu-card {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
}

.settings-menu-card > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.settings-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    overflow-y: scroll;
    scrollbar-width: none;
}

.settings-menu > div {
    display: flex;
    flex-direction: column;
    flex-basis: 47%;
    flex-grow: 1;
    gap: 1rem;
    max-width: 100%;
}
.settings-menu > div > section {
    display: flex;
    flex-direction: column;
}

.settings-menu > div .horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.settings-menu > div .horizontal > * {
    flex-grow: 1;
}

.role-specific-menu-settings {
    display: flex;
    flex-direction: column;
    max-height: 20rem;
    overflow-y: scroll;
    gap: 0.25rem;
}

.role-specific-menu-settings-selector {
    display: flex;
    border: .13rem solid var(--primary-border-color);
    background-color: var(--secondary-color);
    border-radius: 0.5rem;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.25rem;
}
.role-specific-menu-settings-selector > span {
    margin: 0 .25rem;
    white-space: pre;
}

.settings-menu > div .standout {
    border: solid .13rem var(--primary-border-color);
    border-top-color: var(--primary-border-shadow-color);
    border-left-color: var(--primary-border-shadow-color);
    border-radius: .5rem;
    padding: 0.25rem;
    background-color: var(--secondary-color);
}

.settings-menu div .settings-volume {
    border: 0;
    padding: 0;
}