.sm {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.sm > main {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 2rem;
    gap: 2rem;
}

.sm > main > section {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sm > main > section#main > div {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}
.sm > main > section#main > h1 {
    font-size: 8em;
    padding: 0;
    margin: 0;
}
.sm > main > section#main > h2 {
    font-size: 3em;
    padding: 0;
    margin: 0;
}
.sm > main > section#main > div > button {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    padding: 0.3rem 0.5rem;
    font-size: 1.5em;
}

.sm > main > section#main {
    width: 60%;
    margin-top: 5%;
    min-height: 40vh;
}

.sm > main > section > h1 {
    width: 100%;
    font-size: 8rem;
    padding-bottom: 1rem;
}

.sm > footer {
    position: static;
    background-color: var(--primary-color);
    border-top: var(--primary-border-color) solid .13rem;
}

.sm nav > ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 2rem;
    gap: 1rem;
}

.sm nav > ul > li {
    padding: 0 1rem;
}