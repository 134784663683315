.will-menu details > div {
    padding: .13rem;
}
.will-menu textarea {
    text-align: left;
    height: 19em;
    resize: none;
    width: 100%;
    margin: 0;
}
.will-menu summary > div {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    align-items: baseline;
    /* This is a little hacky. This whole thing is a little hacky.*/
    padding-right: .75rem;
}
.will-menu summary > div > div {
    display:flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    align-items: center;
}