.large-forger-menu {
    padding-top: .2rem;
}
.large-forger-menu textarea {
    text-align: left;
    height: 19em;
    resize: none;
    width: 100%;
    margin: 0;
}
.large-forger-menu div {
    margin: .2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
