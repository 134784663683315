.play-menu {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 2rem;
}

.play-menu-browser {
    display: flex;
    flex-direction: column;

    background-color: var(--background-color);
    border: solid .13rem var(--background-border-color);
    border-bottom-color: var(--background-border-shadow-color);
    border-right-color: var(--background-border-shadow-color);
    border-radius: 1rem;
    padding: 1rem;
    gap: 1rem;
    
    width: 100%;
    height: 100%;
}

.play-menu-browser > header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.play-menu-browser > footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;
}

.play-menu-browser > footer > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: baseline;
}
.play-menu-browser > footer > div > label {
    display: block;
}

.play-menu-browser > header h1 {
    text-align: left;
}

.play-menu-browser > header button {
    padding: 0.5rem;
}

.play-menu-browser > .play-menu-center {
    flex-grow: 1;

    border: solid .13rem var(--background-border-color);
    border-top-color: var(--background-border-shadow-color);
    border-left-color: var(--background-border-shadow-color);
    border-radius: 0.5rem;

    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .play-menu {
        padding: 0;
    }
    .play-menu-browser {
        border-radius: 0;
        padding: 2rem 0 1rem 0;
        border: none;
    }
    .play-menu-browser > .play-menu-center {
        border-left: none;
        border-right: none;
        border-radius: 0;
    }
    .play-menu-browser > header {
        padding: 0 1rem 0 1rem;
    }
    
    .play-menu-browser > footer {
        padding: 0 1rem 0 1rem;
    }
}

.play-menu-browser > .play-menu-center > table {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    overflow: hidden;
}

.play-menu-browser table tr:nth-last-child(even) {
    background-color: var(--secondary-color);
}

.play-menu-browser table > thead {
    width: 100%;
    flex-shrink: 0;
    background-color: var(--background-color);
    overflow: hidden;
}

.play-menu-browser table > tbody {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.play-menu-browser table > tfoot {
    width: 100%;
    overflow: hidden;
}

.play-menu-browser table tr {
    display: flex;
    flex-direction: row;
    flex: 0 0;
    width: 100%;
}

.play-menu-browser tfoot > tr, .play-menu-browser thead > tr {
    height: 2em;
}

.play-menu-browser table th, .play-menu-browser table td {
    display: flex;
    padding: 0.25rem 0.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    flex-shrink: 0;
    min-width: 0;
    border-right: .13rem solid var(--primary-border-color);
}

.play-menu-browser table th:first-of-type, .play-menu-browser table td:first-of-type {
    flex-basis: 4rem;
}

.play-menu-browser table th:nth-of-type(2), .play-menu-browser table td:nth-of-type(2) {
    flex-basis: 8rem;
    justify-content: left;
    overflow: hidden;
}

.play-menu-browser table th:nth-of-type(3), .play-menu-browser table td:nth-of-type(3) {
    flex-basis: calc(100% - 12rem);
    justify-content: left;
    border-right: none;
}

.play-menu-browser table td .play-menu-lobby-player-list {
    text-align: left;
    overflow-x: auto;
    width: 100%;
}