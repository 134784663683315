.spectator-game-screen {
    font-size: 1.5em;
}
.spectator-game-screen .fast-forward-button {
    position: fixed;
    top: 0.2rem;
    left: 0.2rem;
    font-size: 1rem;
}
.spectator-game-screen .phase-started {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
}
.spectator-game-screen .obituary-screen,
.spectator-game-screen .briefing-screen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.spectator-game-screen .nomination-screen,
.spectator-game-screen .trial-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.spectator-game-screen .obituary-screen .grave {
    background-color: var(--background-color);
    border: .13rem solid var(--background-border-color);
    border-right-color: var(--background-border-shadow-color);
    border-bottom-color: var(--background-border-shadow-color);
    border-radius: 1rem;
    padding: 1rem;
}
.spectator-game-screen .briefing-screen .fast-forward-button {
    position: static;
    font-size: 1em;
    outline: .13rem yellow solid;
}