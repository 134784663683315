
.anchor {
    overflow: hidden;
    position: absolute;
    
    height: 100dvh;
    width: 100dvw;

    background-color: var(--background-color);
}

.error-card {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 5%;
    bottom: 10%;
    height: fit-content;
    width: 20%;
    min-width: 15rem;
    box-shadow: 0 0 .5rem #0007;
    
    border-radius: 0.4rem;
    border-style: solid;
    padding: .5rem;
    background-color: var(--secondary-color);
    border-color: var(--primary-border-color);
    border-bottom-color: var(--primary-border-shadow-color);
    border-right-color: var(--primary-border-shadow-color);
    border-width: .13rem;
    color: white;
}

.error-card > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 125%;
}

.error-card > header > button.close {
    font-size: 0.75em;
    font-weight: bold;
    width: 1.2em;
    height: 1.2em;
}

.error-card > div {
    margin-top: 3%;
    text-align: left;
}

@media (prefers-reduced-motion = no-preference) {
    .error-card {
        /* Start off the page and slide in */
        transform: translateX(100%);
        animation: slide-in 0.5s forwards;
        -webkit-animation: slide-in 0.5s forwards;
    }
}
    
@keyframes slide-in {
    100% { transform: translateX(0%); }
}

.anchor .global-menu-button {
    z-index: 101;
    position: fixed;
    right: .2rem;
    top: .2rem;
    height: fit-content;
    width: fit-content;
}


.anchor-cover-card {
    display: flex;
    position: relative;
    background-color: var(--background-color);
    border: 0.13rem solid var(--background-border-color);
    border-radius: 1rem;
    border-right-color: var(--background-border-shadow-color);
    border-bottom-color: var(--background-border-shadow-color);
    overflow-y: hidden;
    box-shadow: 0 0 .5rem #0007;
}

.anchor-cover-card-content {
    display: flex;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
}

.anchor-cover-card .close-button {
    position: absolute;
    padding: 0;
    top: 0.5rem;
    right: 0.5rem;
    border-radius: .5rem;
}

.anchor-cover-card-background-cover {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4rem 2rem;
}

.anchor-cover-card-background-cover::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--fade-color, var(--background-color));
    opacity: 80%;
}